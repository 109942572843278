import './App.css';
import React, { useState, useEffect } from "react";
import { useMediaQuery } from 'react-responsive';

class AccessPoint {
  constructor(ap, method, channel) {
    this.ap = ap;
    this.method = method;
    this.channel = channel;
  }
}

function App() {
  const [apList, setApList] = useState(null);
  const [ssid, setSsid] = useState('Network Name');
  const [fwVer, setFwVer] = useState(null);
  const [macAddr, setMacAddr] = useState(null);
  const [method, setMethod] = useState('wpa-personal');
  const [channel, setChannel] = useState(null);
  const [security, setSecurity] = useState('eap-peap');
  const [anon, setAnon] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [displayPassword, setDisplayPassword] = useState(null);
  const [eapDisabled, setEapDisabled] = useState(false);
  const [passwordDisabled, setPasswordDisabled] = useState(false);
  const [connectionString, setConnectionString] = useState('NOT CONNECTED');
  const [ipAddr, setIpAddr] = useState('NO');
  const [connectionBio, setConnectionBio] = useState(false);
  const [bexFwVer, setBexFwVer] = useState(null);
  const [bexSn, setBexSn] = useState(null);
  const [nrAps, setNrAps] = useState(0);

  const test_mode = true;    // true -> build for webserver with stubs, false -> build for WE310G4

  var url;
  if (test_mode) {
    url = "https://mgbio.eu.pythonanywhere.com";
  } else {
    url = "http://192.168.230.3";
  }

  const isMobile = useMediaQuery({ maxWidth: 991 });

  useEffect(() => {
    if (window.BEX_FW_VER) {
      setBexFwVer(window.BEX_FW_VER);
      setBexSn(window.BEX_SN);
    }

    fetch(url + "/sys/fw/ver", {
      method: 'GET'
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        const { convertXML } = require("simple-xml-to-json");
        const myJson = convertXML(data);
        setFwVer(myJson.version.children[0].current.children[0].app.content);
      })
      .catch((error) => console.log(error));

    fetch(url + "/sys/cfg/net", {
      method: 'GET'
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        const { convertXML } = require("simple-xml-to-json");
        const myJson = convertXML(data);
        setMacAddr(myJson.network.children[5].mac_addr.content);
      })
      .catch((error) => console.log(error));

    fetch(url + "/sys/prov/ap_list", {
      method: 'GET'
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
        const { convertXML } = require("simple-xml-to-json");
        const myJson = convertXML(data).ap_list.children;
        const aps = [];
        for (let i = 0; i < myJson.length; i++) {
          if (myJson[i].ap.children[1].ssid.content !== undefined) {
            aps.push(new AccessPoint(myJson[i].ap.children[1].ssid.content, myJson[i].ap.children[4].security.content, myJson[i].ap.children[5].channel.content));
          }
        }
        setApList(aps);
        setNrAps(aps.length)
        console.log(aps);
      })
      .catch((error) => console.log(error));
  }, [test_mode, url]);

  const handleClick_ssid = (label) => {
    setSsid(label.ap);
    setMethod(label.method);
    if (label.method === "wpa-enterprise") {
      setEapDisabled(false);
      setPasswordDisabled(false);
    } else if (label.method === "wpa-personal") {
      setEapDisabled(true);
      setAnon('');
      setUsername('');
      setPasswordDisabled(false);
    } else {
      setEapDisabled(true);
      setAnon('');
      setUsername('');
      setPasswordDisabled(true);
      setPassword('');
    }
    setChannel(label.channel);
  };

  const handleChange_ssid = (event) => {
    setSsid(event.target.value);
  };

  const handleChange_channel = (event) => {
    setChannel(event.target.value);
  };

  const handleChange_method = (event) => {
    setMethod(event.target.value);
    if (event.target.value === "wpa-enterprise") {
      setEapDisabled(false);
      setPasswordDisabled(false);
    } else if (event.target.value === "wpa-personal") {
      setEapDisabled(true);
      setPasswordDisabled(false);
    } else {
      setEapDisabled(true);
      setPasswordDisabled(true);
    }
  };

  const handleChange_security = (event) => {
    setSecurity(event.target.value);
  };

  const handleChange_anon = (event) => {
    setAnon(event.target.value);
  };

  const handleChange_username = (event) => {
    setUsername(event.target.value);
  };

  const handleChange_password = (event) => {
    setPassword(event.target.value);
  };

  const handleChange_displayPassword = () => {
    setDisplayPassword(!displayPassword);
  };

  const connect = () => {
    const x = new Date();
    var xmlData = "<network><mode>client</mode><client><wireless><ssid>" + ssid + "</ssid><channel>" + channel + "</channel><security>" + method + "</security><eap_juliantime>" + x.toLocaleString('en-GB') + "</eap_juliantime>";
    if (method === "wpa-personal") {
      xmlData = xmlData + "<password>" + password + "</password>";
    } else if (method === "wpa-enterprise") {
      xmlData = xmlData + "<eapmethod>" + security + "</eapmethod><eap_username>" + username + "</eap_username><eap_password>" + password + "</eap_password>";
    }
    xmlData = xmlData + "</wireless><ip><ip_type>dhcp</ip_type></ip></client><mode>client</mode></network>";
    console.log(xmlData);
    fetch(url + "/sys/cfg/net", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/xml'
      },
      body: xmlData
    })
      .then(response => response.text())
      .then(data => {
        console.log('Success:', data);
        const { convertXML } = require("simple-xml-to-json");
        const myJson = convertXML(data);
        setConnectionString('CONNECTED');
        setIpAddr(myJson.verification.children[1].client.children[1].ip.children[1].ip_addr.content)
        // TODO: Replace with real connection test using the WE310G4
        setConnectionBio(true);
      })
      .catch((error) => {
        console.error('Error:', error);
        setConnectionString('NOT CONNECTED');
        setIpAddr('NO');
        setConnectionBio(true);
      });
  };

  const height_style = (h) => {
    return {
      height: h + 'px',
    };
  };

  const top_style = (t) => {
    return {
      top: t + 'px',
    };
  };

  let ap_list;
  if (apList) {
    ap_list = <div class="network-list">{apList.map(ap => <div class="networksetting-wifi"><button class="button-ap-list" onClick={() => handleClick_ssid(ap)}><div class="vector-wrapper"><img class="img" src="vector.svg" /></div><div class="text-wrapper-4">{ap.ap}</div></button></div>)}</div>;
  } else {
    ap_list = <div class="label"><p class="text-wrapper-3">Scanning for Wi-fi networks ...</p></div>;
  }

  let connection_bio;
  if (connectionBio) {
    connection_bio = <div class="status"><div class="vector-wrapper"><img class="vector" src="save-2.svg" /></div><div class="status-wrapper"><div class="status-connected">YES</div></div></div>;
  } else {
    connection_bio = <div class="status"><div class="vector-wrapper"><img class="vector" src="vector-6.svg" /></div><div class="status-wrapper"><div class="status-3">NO</div></div></div>;
  }

  let button_disabled;
  if (method === "wpa-enterprise" && (username === '' || password === '')) {
    button_disabled = true;
  } else if (method === "wpa-personal" && password === '') {
    button_disabled = true;
  } else {
    button_disabled = false;
  }

  return (
    <div class="entry">
      <div class={"div" + (isMobile ? "-mobile" : "")} style={isMobile ? height_style(1139 + 28 * nrAps) : height_style(982 + 28 * nrAps)}>
        <img class={"BIOTRONIK-black" + (isMobile ? "-mobile" : "")} style={isMobile ? top_style(1082 + 28 * nrAps) : top_style(925 + 28 * nrAps)} src="biotronik-black-noclaim-1-1.svg" />
        <div class={"panels" + (isMobile ? "-mobile" : "")}>
          <div class="div-2">
            <div class="frame">
              <div class="div-wrapper">
                <p class={"BIOTRONIK-bioexpress" + (isMobile ? "-mobile" : "")}>
                  <span class="text-wrapper">BIOTRONIK BIOexpress </span> <span class="span">(SN: {bexSn})</span>
                </p>
              </div>
              <div class={"div-3" + (isMobile ? "-mobile" : "")}>
                <div class={"div-4" + (isMobile ? "-mobile" : "")}>
                  <div class="device-status">
                    <div class="element">Wi-Fi</div>
                    <div class="status">
                      <div class="status-wrapper"><div class="status-2">{connectionString}</div></div>
                    </div>
                  </div>
                  <div class="device-status">
                    <div class="element">IP address</div>
                    <div class="status">
                      <div class="status-wrapper"><div class="status-2">{ipAddr}</div></div>
                    </div>
                  </div>
                  <div class="device-status">
                    <div class="element">Connection to BIOTRONIK</div>
                    {connection_bio}
                  </div>
                </div>
                <div class={"div-4" + (isMobile ? "-mobile" : "")}>
                  <div class="device-status">
                    <div class="element">BIOexpress FW version</div>
                    <div class="status">
                      <div class="status-wrapper"><div class="status-2">{bexFwVer}</div></div>
                    </div>
                  </div>
                  <div class="device-status">
                    <div class="element">Modem FW version</div>
                    <div class="status">
                      <div class="status-wrapper"><div class="status-2">{fwVer}</div></div>
                    </div>
                  </div>
                  <div class="device-status">
                    <div class="element">MAC address</div>
                    <div class="status">
                      <div class="status-wrapper"><div class="status-2">{macAddr}</div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="div-2">
            <div class="frame">
              <div class="div-wrapper"><div class="text-wrapper-2">Network Setup</div></div>
              <div class={"div-3" + (isMobile ? "-mobile" : "")}>
                <div class={"SSID-list" + (isMobile ? "-mobile" : "")}>
                  <div class={"wifi-scan" + (isMobile ? "-mobile" : "")}>
                    {ap_list}
                  </div>
                </div>
                <div class={"SSID-manual-setup" + (isMobile ? "-mobile" : "")}>
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Network (SSID)</div></div>
                    <div class="frame-wrapper">
                      <input class="config-form" type="text" value={ssid} onChange={handleChange_ssid}></input>
                    </div>
                  </div>
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Channel</div></div>
                    <div class="frame-wrapper">
                      <input class="config-form" type="text" value={channel} onChange={handleChange_channel}></input>
                    </div>
                  </div>
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Method</div></div>
                    <div class="frame-wrapper">
                      <select class="config-form" id="options" value={method} onChange={handleChange_method}>
                        <option value="wpa-personal">WPA-Personal</option>
                        <option value="wpa-enterprise">WPA-Enterprise</option>
                        <option value="none">Open</option>
                      </select>
                    </div>
                  </div >
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">EAP Security</div></div>
                    <div class="frame-wrapper">
                      <select class="config-form" id="options" value={security} onChange={handleChange_security} disabled={eapDisabled}>
                        <option value="eap_peap">PEAPV0-MSCHAPV2</option>
                        <option value="eap_fast">FAST-MSCHAPV2</option>
                      </select>
                    </div>
                  </div >
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Anonymous identity</div></div>
                    <div class="frame-wrapper">
                      <input class="config-form" type="text" value={anon} onChange={handleChange_anon} disabled={eapDisabled}></input>
                    </div>
                  </div >
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Username</div></div>
                    <div class="frame-wrapper">
                      <input class="config-form" type="text" value={username} onChange={handleChange_username} disabled={eapDisabled}></input>
                    </div>
                  </div >
                  <div class={"networksetting" + (isMobile ? "-mobile" : "")}>
                    <div class="SSID-network-wrapper"><div class="text-wrapper-3">Password</div></div>
                    <div class="frame-wrapper">
                      <input class="config-form" type={displayPassword ? 'text' : 'password'} value={password} onChange={handleChange_password} disabled={passwordDisabled}></input>
                    </div>
                  </div >
                  <div class={"networksetting-2" + (isMobile ? "-mobile" : "")}>
                    <input class="vector-wrapper"
                      type="checkbox"
                      checked={displayPassword}
                      onChange={handleChange_displayPassword}
                    />
                    <div class="text-wrapper-6">Display password</div>
                  </div >
                </div >
              </div >
            </div >
            <div class="button-wrapper">
              <button class="button" onClick={connect} disabled={button_disabled}>
                <img class="vector-wrapper" src="save-1-2.svg" />
                <div class="save-and-connect">{button_disabled ? 'INCOMPLETE' : 'SAVE AND CONNECT'}</div>
              </button>
            </div>
          </div >
        </div >
      </div >
    </div >
  );
}

export default App;
